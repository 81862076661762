import { cx } from '@emotion/css'
import { TimeslotModel } from 'api/models/timeslot.model.ts'
import { SanekFriendInfo } from 'components/sanek-friend-info'
import { TIME_TEMPLATE } from 'const/date.const.ts'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { JSX, useEffect } from 'react'
import { invitationAcceptStore } from 'store/invitation-accept.store.ts'
import { meetingDetailsStore } from 'store/meeting-details.store.ts'
import { TPickerTimeslot } from 'types/time-picker.types.ts'
import { CellEmpty } from 'ui/cell-empty.tsx'
import { FlexContainer } from 'ui/flex-container'
import { SanekButton } from 'ui/sanek-button'
import { Typography } from 'ui/typography'
import { toPickerTimeslot } from 'utils/time-picker.utils.ts'
import * as styles from './recipient-search-content.style.ts'
import { TimeslotTimeSelect } from './timeslot-time-select'

const AVAILABLE_TIMESLOT_END_DATETIME = dayjs().add(2, 'h')
export const RecipientSearchContent = observer((): JSX.Element => {
  const { meeting } = meetingDetailsStore
  const { selectedTimeslot, isAccepting, isDeclining } = invitationAcceptStore

  useEffect(() => {
    return () => invitationAcceptStore.reset()
  }, [])

  const handleTimeslotClick = (timeslot: TimeslotModel) => {
    invitationAcceptStore.setSelectedTimeslot(toPickerTimeslot(timeslot))
  }

  const handleTimeslotChange = (timeslot: TPickerTimeslot): void => {
    invitationAcceptStore.setSelectedTimeslot(timeslot)
  }

  if (!meeting) {
    return <></>
  }

  const handleSetDecision = (isAccepted: boolean): void => {
    if (!meeting.invitationId || (isAccepted && !selectedTimeslot)) {
      // TODO обработка ошибки
      return
    }

    void invitationAcceptStore.setIsInvitationAccepted(
      meeting.id,
      isAccepted,
      selectedTimeslot
    )
  }

  const handleTimeSlotClean = (): void => {
    invitationAcceptStore.setCleanTimeslot()
  }

  return (
    <>
      <div className={styles.chooseTimeslotContainer}>
        <Typography family="Chalkboard" size="24" weight="700" align="center">
          Выбери время
        </Typography>
        <CellEmpty height="20" />
        {selectedTimeslot ? (
          <>
            <div
              className={cx(
                styles.chooseTimeslotButton,
                styles.chooseTimeslotButtonActive
              )}
            >
              <Typography align="center">
                {getTimeslotLabel(selectedTimeslot)}
              </Typography>
            </div>

            <TimeslotTimeSelect
              timeslot={selectedTimeslot}
              onChange={handleTimeslotChange}
            />
            <button
              type="button"
              onClick={handleTimeSlotClean}
              className={cx(styles.secondaryButton)}
            >
              Назад
            </button>
          </>
        ) : (
          <>
            {meeting.timeslots?.map((timeslot: TimeslotModel) => (
              <button
                className={cx(styles.chooseTimeslotButton, {
                  [styles.disabledTimeslot]: timeslot.timeTo.isBefore(
                    AVAILABLE_TIMESLOT_END_DATETIME,
                    'm'
                  ),
                })}
                onClick={handleTimeslotClick.bind(null, timeslot)}
              >
                <Typography align="center">
                  {getTimeslotLabel(timeslot)}
                </Typography>
              </button>
            ))}
          </>
        )}
      </div>
      <CellEmpty height="20" />

      {meeting.counterpart && (
        <>
          <Typography family="Chalkboard" size="26" weight="700">
            Друг Санька
          </Typography>
          <CellEmpty height="15" />

          <SanekFriendInfo friend={meeting.counterpart} />
          <CellEmpty height="20" />
        </>
      )}

      <FlexContainer justify="space-between" wrap="nowrap">
        <SanekButton
          type="clear"
          size="l"
          disabled={isAccepting}
          isLoading={isDeclining}
          style={{ width: '45%' }}
          onClick={handleSetDecision.bind(null, false)}
        >
          <Typography size="24" weight="700" align="center">
            НЕ ИДУ
          </Typography>
        </SanekButton>

        <SanekButton
          type="primary"
          size="l"
          disabled={!selectedTimeslot || isDeclining}
          isLoading={isAccepting}
          withDecoration
          className={styles.acceptButton}
          onClick={handleSetDecision.bind(null, true)}
        >
          <Typography size="36" weight="700" align="center">
            ИДУ
          </Typography>
        </SanekButton>
      </FlexContainer>
    </>
  )
})

function getTimeslotLabel(timeslot: TimeslotModel | TPickerTimeslot): string {
  const date = timeslot.date.format('dd, D MMMM')
  const timeFrom = timeslot.timeFrom.format(TIME_TEMPLATE)
  const timeTo = timeslot.timeTo.format(TIME_TEMPLATE)

  return `${date}\u00a0\u00a0\u00a0${timeFrom} - ${timeTo}`
}
