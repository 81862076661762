import femaleGender from 'assets/female.svg'
import maleGender from 'assets/male.svg'
import randomGender from 'assets/random-gender.svg'
import cx from 'classnames'
import { RandomGender, UserGender } from 'enums/user.enum.ts'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { meetingCreationStore } from 'store/meeting-creation.store.ts'
import { userStore } from 'store/user.store.ts'
import * as styles from './gender-selector.style.ts'

const genderMap = {
  [UserGender.FEMALE]: {
    title: 'Подругу',
    image: femaleGender,
  },
  [UserGender.MALE]: {
    title: 'Друга',
    image: maleGender,
  },
}

export const GenderSelector: FC = observer(() => {
  const { user } = userStore
  const { setSelectedGender, selectedGender } = meetingCreationStore

  const userGender = user?.gender

  // Устанавливаем пол пользователя по умолчанию при монтировании компонента
  useEffect(() => {
    if (userGender) {
      setSelectedGender(userGender)
    }
  }, [userGender, selectedGender, setSelectedGender])

  if (!userGender) {
    return null
  }

  const { title, image } = genderMap[userGender]

  const handleGenderClick = () => {
    setSelectedGender(userGender)
  }

  const handleRandomGenderClick = () => {
    setSelectedGender(RandomGender)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Кого подобрать?</div>
      <div className={styles.container}>
        <div
          className={cx(styles.card, {
            [styles.selected]: selectedGender === userGender,
          })}
          onClick={handleGenderClick}
        >
          <div>
            <img src={image} alt={title} />
          </div>
          <div>
            <p>{title}</p>
          </div>
        </div>
        <div
          className={cx(styles.card, {
            [styles.selected]: selectedGender === RandomGender,
          })}
          onClick={handleRandomGenderClick}
        >
          <div>
            <img src={randomGender} alt="Неважно" />
          </div>
          <div>
            <p>Неважно</p>
          </div>
        </div>
      </div>
    </div>
  )
})
